import Rellax from 'rellax';
import { Nav, MobileNav } from './nav.js';
import VideoPlayer from './video-player.js';

const modules = {};

// Initialize the navbar.
const nav = document.querySelector('header nav');
const mobileNav = document.querySelector('nav.mobile');
if (nav) modules.nav = new Nav(nav);
if (mobileNav) modules.mobileNav = new MobileNav(mobileNav);

const rellax = new Rellax('.rellax');

// Initialize the video player on the homepage.
const homepageVideoContainer = document.querySelector('.container--video-player');
if (homepageVideoContainer) modules.videoPlayer = new VideoPlayer(homepageVideoContainer);
