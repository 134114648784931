function isElement(o) {
  return (
    typeof HTMLElement === 'object' ? o instanceof HTMLElement :
      o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string'
  );
}

export default class VideoPlayer {
  constructor(target) {
    this.dom = {};
    this.target = target;
    this.id = target.getAttribute('data-youtube-id');

    this.initializeEvents();
  }

  get target() {
    return this.dom.target;
  }

  set target(target) {
    if (!target) {
      throw Error('ERROR: VideoPlayer invoked without container element.');
    }

    if (!isElement(target)) {
      throw Error('ERROR: VideoPlayer invoked with non-DOM object.');
    }

    this.dom.target = target;
    this.dom.play = target.querySelector('.action--play-video');
    this.dom.close = target.querySelector('.action--close');
  }

  initializeEvents() {
    this.dom.play.addEventListener('click', this.handlePlay.bind(this), false);
    this.dom.close.addEventListener('click', this.handleClose.bind(this), false);
  }

  handleClose() {
    this.target.classList.remove('playing');
    this.dom.player.remove();
  }

  handlePlay() {
    this.target.classList.add('playing');

    this.dom.player = document.createElement('iframe');
    this.dom.player.setAttribute('src', `https://www.youtube.com/embed/${this.id}?autoplay=true`);
    this.dom.player.setAttribute('frameborder', 0);

    this.target.appendChild(this.dom.player);
  }
}
