export class Nav {
  constructor(target) {
    this.target = target;

    this.dom = {};

    this.dom.search = target.querySelector('.action--search');
    this.dom.form = target.querySelector('form');
    this.dom.input = this.dom.form.querySelector('input');
    this.dom.util = target.querySelector('ul.utility');

    this.handleSearchActivate = this.handleSearchActivate.bind(this);
    this.handleSearchCancel = this.handleSearchCancel.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleInputKeyup = this.handleInputKeyup.bind(this);

    this.dom.search.addEventListener('click', this.handleSearchActivate, false);
  }

  handleSearchActivate(event) {
    event.preventDefault();

    this.dom.search.removeEventListener('click', this.handleSearchActivate, false);
    this.dom.search.addEventListener('click', this.handleSearch, false);

    this.dom.input.focus();
    this.dom.input.addEventListener('keyup', this.handleInputKeyup, false);

    const net = document.createElement('div');
    net.style.position = 'absolute';
    net.style.top = 0;
    net.style.right = 0;
    net.style.width = '100%';
    net.style.height = '100%';
    net.style.cursor = 'pointer';
    net.style.zIndex = '9999';

    document.body.appendChild(net);

    net.addEventListener('click', this.handleSearchCancel, false);
    this.dom.net = net;

    this.dom.form.classList.add('active');
  }

  handleSearch(event) {
    event.preventDefault();
    this.dom.form.submit();
  }

  handleSearchCancel() {
    this.dom.form.classList.remove('active');
    this.dom.net.remove();
    this.dom.input.value = '';
    this.dom.search.removeEventListener('click', this.handleSearch, false);
    this.dom.search.addEventListener('click', this.handleSearchActivate, false);
  }

  handleInputKeyup(event) {
    if (event.keyCode === 27) {
      this.handleSearchCancel();
      this.dom.input.removeEventListener('keyup', this.handleInputKeyup, false);
    }
  }
}

export class MobileNav {
  constructor(target) {
    this.target = target;

    this.state = {
      active: false,
    };

    this.dom = {};
    this.dom.actuate = document.querySelector('.--js-action-menu-actuate');

    this.handleNavActivate = this.handleNavActivate.bind(this);
    this.dom.actuate.addEventListener('click', this.handleNavActivate, false);
  }

  handleNavActivate(event) {
    event.preventDefault();
    this.state.active = !this.state.active;
    this.target.classList.toggle('active');
  }
}
